@tailwind base;
@tailwind components;
@tailwind utilities;

.popinsFont {
  font-family: "Poppins", sans-serif;
}
.robotoFont {
  font-family: "Poppins", sans-serif;
}
.robotoFlexFont {
  font-family: "Roboto Flex", sans-serif;
}
