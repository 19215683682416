#detailpage-press {
  .press .press-title {
    width: 190px;
    height: 60px;
    margin-bottom: 80px;
  }

  .press .press-title .press-text {
    width: 175px;
    height: 60px;

    /* H2 */

    font-family: "Poppins";
    font-style: italic;
    font-weight: 900;
    font-size: 60px;
    line-height: 60px;
    /* identical to box height, or 100% */

    letter-spacing: -0.01em;

    color: #101010;
    margin-bottom: 80px;
  }

  .press-content .content .category {
    width: 100%;
    height: auto;

    font-family: "Roboto Flex";
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    color: #db2f33;
  }

  .press-content .content .question1 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height */

    letter-spacing: -0.01em;

    /* BLACK */

    color: #101010;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  .press-content .content .question2 {
    font-family: "Roboto Flex";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 38px;
    /* identical to box height, or 238% */

    /* BLACK */

    color: #101010;

    opacity: 0.8;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  .press-content .content {
    margin-top: 30px;
  }

  .press-content .content hr {
    margin-top: 30px;
  }

  .press {
    margin-bottom: 180px;
  }

  .dot {
    width: 15px;
    height: 60px;

    font-family: "Poppins";
    font-style: italic;
    font-weight: 900;
    font-size: 60px;
    line-height: 60px;

    letter-spacing: -0.01em;

    color: #db2f33;
  }

  // --------------------------------------1023px

  @media only screen and (max-width: 1023px) {
    .press {
      margin-bottom: 100px;
    }

    .press .press-title {
      margin-bottom: 40px;
    }

    .press .press-title .press-text {
      width: 135px;
      height: 44px;

      /* m_H1 Black Italic */

      font-family: "Poppins";
      font-style: italic;
      font-weight: 900;
      font-size: 40px;
      line-height: 44px;
      /* identical to box height, or 110% */

      letter-spacing: -0.01em;

      /* BLACK */

      color: #101010;
      margin-bottom: 0px;
    }

    .press-content .content {
      margin-top: 24px;
    }

    .press-content .content .category {
      font-family: "Roboto Flex";
      font-style: italic;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      /* identical to box height, or 171% */

      color: #db2f33;
    }

    .press-content .content .question1 {
      // padding-bottom: 15px;
      display: inline-block;
      margin-bottom: 15px;
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 24px;
      /* or 133% */

      letter-spacing: -0.01em;

      /* BLACK */

      color: #101010;
      margin-bottom: 6px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }

    .press-content .content .question2 {
      /* m_Body 13pt Poppins */
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 20px;

      letter-spacing: -0.01em;

      color: #101010;
      margin-bottom: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
    .press-content .content hr {
      margin-top: 25px;
    }

    .investment .investment-content {
      display: none;
    }
    .header .menu {
      display: none;
    }

    .dot {
      width: 255px;
      height: 44px;

      /* m_H1 Black Italic */

      font-family: "Poppins";
      font-style: italic;
      font-weight: 900;
      font-size: 40px;
      line-height: 44px;
      /* identical to box height, or 110% */

      letter-spacing: -0.01em;

      /* BLACK */

      color: #db2f33;
    }
  }
}
