#detailpage-container {
    .container {
        width: 68.75%;
        margin: 0 auto;
    }

    // --------------------------------------1023px

    @media only screen and (max-width: 1023px) {
        .container {
            width: 80%;
            margin: 0 auto;
        }
    }
}