.rootfooter {
  width: 100%;
  padding-bottom: 55px;
  padding-top: 50px;
  padding-left: 9.5%;
  padding-right: 9.5%;
  background-color: #e8e9eb;
  .mainfooter {
    display: flex;
    width: 100%;
    margin: auto;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    // padding-top: 5px;
    .logofooter {
      width: 116px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .addressdetailfooter {
      // height: 16px;

      font-weight: 400;
      font-size: 11px;
      line-height: 16px;
      /* identical to box height, or 145% */

      letter-spacing: -0.01em;

      color: #101010;

      opacity: 0.4;
      margin-top: 10px;
      margin-left: 30px;
    }
    .allrightframe {
      margin-left: auto;
      margin-top: 10px;
      // height: 16px;

      font-weight: 400;
      font-size: 11px;
      line-height: 16px;
      /* identical to box height, or 145% */

      letter-spacing: -0.01em;

      /* BLACK */

      color: #101010;

      opacity: 0.4;
    }
    .framelogomb {
      display: none;
    }

    .framelogopc {
      display: flex;
      margin-left: auto;
      .framelogofb {
        /* Auto layout */
        margin-left: 20px;
        width: 34px;
        height: 34px;
        border-radius: 100px;
        background-color: #dddedf;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        img {
          opacity: 0.4;
        }
      }
      .framelogofb:hover {
        cursor: pointer;
        transition: all;
        background-color: #cccdcf;
      }
      .framelogolk {
        cursor: pointer;

        margin-left: 10px;
        width: 34px;
        height: 34px;
        background-color: #dddedf;
        border-radius: 100px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        img {
          opacity: 0.4;
        }
      }
      .framelogolk:hover {
        cursor: pointer;
        background-color: #cccdcf;
      }
    }
  }
}

//RESPONSIVE
@media screen and (max-width: 769px) {
  .rootfooter {
    width: 100%;
    padding-bottom: 90px;
    padding-top: 37px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 40px;
    .mainfooter {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      margin: auto;
      padding-left: 20px;
      padding-right: 20px;
      .frameaddress {
        display: flex;
        margin-top: 15px;
        width: 100%;
        .addressdetailfooter {
          height: auto;
          width: 100%;
          font-weight: 400;
          font-size: 9px;
          line-height: 16px;
          /* identical to box height, or 145% */
          letter-spacing: -0.01em;
          text-align: left;
          color: #101010;
          opacity: 0.4;
          margin-top: 10px;
          margin-left: 0px;
          margin-right: 30px;
        }
        .framelogomb {
          display: flex;
          .framelogofb-mb {
            cursor: pointer;
            display: block;
            margin-top: 10px;
            margin-left: 0px;
            width: 34px;
            height: 34px;
            border-radius: 100px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            background-color: #dddedf;
            img {
              opacity: 0.4;
            }
          }
          .framelogolk-mb {
            cursor: pointer;
            display: block;
            margin-top: 10px;
            margin-left: 12px;
            width: 34px;
            height: 34px;
            border-radius: 100px;
            background-color: #dddedf;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            img {
              opacity: 0.4;
            }
          }
        }
      }
      .allrightframe {
        height: 16px;

        font-weight: 400;
        font-size: 9px;
        line-height: 16px;
        /* identical to box height, or 145% */

        letter-spacing: -0.01em;

        /* BLACK */

        color: #101010;

        opacity: 0.4;
        margin: 0px;
        margin-top: 15px;
      }
      .framelogopc {
        display: none;
      }
    }
  }
}
