.rootpagedetail {
  .framecontentmain {
    display: flex;
    justify-content: center;
    // column-gap: 10px;
    border-bottom: 0.5px solid #000000;
    background: #ffffff;
    .detailcontent-active {
      width: 130px;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      /* identical to box height, or 160% */
      letter-spacing: -0.02em;
      color: #101010;
      border-bottom: 3px solid black;
      padding-bottom: 15px;
      cursor: pointer;
      padding-top: 20px;

      text-align: center;
      transition-duration: 150ms;
      transition-property: all;
    }
    .detailcontent {
      /* Gallery */
      width: 130px;
      text-align: center;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      /* identical to box height, or 160% */
      padding-bottom: 15px;
      padding-top: 20px;
      letter-spacing: -0.02em;
      color: #999999;
      cursor: pointer;
      transition-duration: 150ms;
      transition-property: all;
    }
    .detailcontent:hover {
      font-weight: 500;
      letter-spacing: -0.02em;
      color: #101010;
      background-color: #f9f9f9;
      padding-bottom: 10px;
      cursor: pointer;
    }
  }

  .framearticle {
    margin-bottom: 150px;
    .framehtmlinterview {
      width: 100%;
      margin: auto;
      height: auto;
      z-index: 100;

      .framequote {
        width: 1320px;
        height: auto;
        margin: auto;
        margin-top: 100px;
        .framedot1 {
          width: fit-content;
          margin-left: 61px;
          margin-top: 40px;
        }
        .frametitleqoute {
          margin-left: 210px;
          margin-right: 210px;

          font-family: "Poppins", sans-serif;
          font-size: 54px;
          line-height: 100%;
          font-style: normal;
          letter-spacing: 0.02em;
          color: #000000;
          width: 907px;
          // height: 54px;
          text-align: center;
          .title1 {
            font-weight: 100;
            --underline-intrinsic-width: 8;

            --underline-width: 18;

            --underline-color: rgba(219, 47, 51, 0.929);

            --underline-cap-width: 4px;

            --underline-offset-y: -7px;

            --underline-padding-x: 0em;
            overflow-wrap: break-word;
            span {
              font-weight: 800;
              font-style: italic;
              display: inline;
              --underline-width-scale: calc(
                var(--underline-width) / var(--underline-intrinsic-width)
              );
              padding: 0
                calc(
                  var(--underline-padding-x) +
                    calc(
                      var(--underline-cap-width) * var(--underline-width-scale)
                    )
                );
              box-decoration-break: clone;
              background-repeat: no-repeat;
              background-image: linear-gradient(
                180deg,
                var(--underline-color),
                var(--underline-color)
              );
              background-position-x: calc(
                  var(--underline-cap-width) * var(--underline-width-scale)
                ),
                0, 100%;
              background-position-y: calc(
                100% - var(--underline-offset-y) * -1
              );
              background-size: calc(
                    100% -
                      calc(
                        var(--underline-cap-width) *
                          var(--underline-width-scale) * 2
                      )
                  )
                  calc(var(--underline-width) * 1px),
                auto calc(var(--underline-width) * 1px),
                auto calc(var(--underline-width) * 1px);
            }
          }
        }
        .framedot2 {
          width: fit-content;
          margin-left: auto;
          margin-right: 86px;
          margin-bottom: 31px;
          margin-top: 15px;
        }
      }

      .framearticle {
        width: 100%;
        margin: auto;
        margin-top: 40px;
        padding-left: 23.5%;
        padding-right: 23.5%;
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 36px;
        text-align: left;
        /* or 180% */
        letter-spacing: -0.02em;
        /* BLACK */
        color: #101010;
        .detailarticle {
          p {
            display: inline-block;
            margin-bottom: 90px;
            margin-top: 55px;
          }
          // p:last-child {
          //   display: inline-block;
          //   margin-top: 55px;
          //   margin-bottom: 90px;
          // }
        }
      }
      .frameimg {
        width: 1320px;
        // height: 450px;
        margin: auto;
        margin-top: 90px;
        img {
          width: 100%;
          height: 100%;
          max-width: 1322px;
          // max-height: 886px;
          object-fit: cover;
          // content: url("https://contrau.metacoders.dev/wp-content/uploads/2022/07/imagearticle.png");
        }
        p {
          font-family: "Roboto Flex", sans-serif;
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 22px;
          /* identical to box height, or 157% */
          text-align: left;
          letter-spacing: -0.02em;

          color: #999999;
          /* Inside auto layout */
          padding-top: 10px;
          flex: none;
          order: 1;
          align-self: stretch;
          flex-grow: 0;
        }
      }
    }
    .framequestionmain {
      width: 100%;
      margin-top: 50px;
      .frametitle {
        width: 100%;
        height: auto;
        background: #151515;
        margin-top: 150px;
        margin-bottom: 150px;
        .detailtitle {
          width: 1046px;
          height: 100%;
          padding-top: 75px;
          padding-bottom: 58px;
          margin: auto;

          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 54px;
          line-height: 100%;
          /* or 54px */
          text-align: left;
          letter-spacing: 0.02em;
          overflow-wrap: break-word;
          color: #ffffff;
          .detailtitlered {
            overflow-wrap: break-word;
            // display: inline-block;
            // height: 52px;

            font-weight: 800;
            // font-weight: 100;
            --underline-intrinsic-width: 8;

            --underline-width: 18;

            --underline-color: rgba(219, 47, 51, 0.929);

            --underline-cap-width: 0px;

            --underline-offset-y: -12px;

            --underline-padding-x: 0em;
            // width: fit-content;
            // display: inline-block;
            // height: 58px;
            // font-weight: 800;
            // border-bottom: 18px solid rgba(219, 47, 51, 0.9294117647);
            display: inline;
            --underline-width-scale: calc(
              var(--underline-width) / var(--underline-intrinsic-width)
            );
            padding: 0
              calc(
                var(--underline-padding-x) +
                  calc(
                    var(--underline-cap-width) * var(--underline-width-scale)
                  )
              );
            box-decoration-break: clone;
            background-repeat: no-repeat;
            background-image: linear-gradient(
              180deg,
              var(--underline-color),
              var(--underline-color)
            );
            background-position-x: calc(
                var(--underline-cap-width) * var(--underline-width-scale)
              ),
              0, 100%;
            background-position-y: calc(100% - var(--underline-offset-y) * -1);
            background-size: calc(
                  100% -
                    calc(
                      var(--underline-cap-width) * var(--underline-width-scale) *
                        2
                    )
                )
                calc(var(--underline-width) * 1px),
              auto calc(var(--underline-width) * 1px),
              auto calc(var(--underline-width) * 1px);
          }
          span {
            font-weight: 100;
          }
        }
      }
      .framequestiontitle {
        width: 100%;
        margin: auto;
        margin-top: 180px;
        padding-left: 23.5%;
        padding-right: 23.5%;
        .questiontitle {
          width: 100%;
          text-align: left;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 40px;
          line-height: 56px;
          /* identical to box height, or 140% */

          letter-spacing: -0.01em;
          /* Inside auto layout */

          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
          color: #101010;
          .redcontent {
            color: #db2f33;
          }
        }
        .articlequestion {
          margin-top: 40px;
          text-align: left;
          font-family: "Roboto Flex", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 36px;
          /* or 180% */

          /* BLACK */

          color: #101010;

          /* Inside auto layout */

          flex: none;
          order: 1;
          align-self: stretch;
          flex-grow: 0;
          .somecannot {
            display: inline-block;
            margin-top: 30px;
          }

          .detailarticle2 {
            padding-top: 40px;
          }
        }
      }
      .framequestiontitle2 {
        width: 100%;
        margin: auto;
        margin-top: 110px;
        padding-left: 23.5%;
        padding-right: 23.5%;
        .questiontitle {
          width: 100%;
          text-align: left;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 40px;
          line-height: 56px;
          /* identical to box height, or 140% */

          letter-spacing: -0.01em;
          /* Inside auto layout */

          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
          color: #101010;
          .redcontent {
            color: #db2f33;
          }
        }
        .articlequestion {
          margin-top: 40px;
          text-align: left;
          font-family: "Roboto Flex", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 36px;
          /* or 180% */

          /* BLACK */

          color: #101010;

          /* Inside auto layout */

          flex: none;
          order: 1;
          align-self: stretch;
          flex-grow: 0;
          .somecannot {
            display: inline-block;
            margin-top: 30px;
          }

          .detailarticle2 {
            padding-top: 40px;
          }
        }
      }

      .frameimgarticle {
        width: 1320px;
        margin: auto;
        margin-top: 0px;
        // margin-top: 165px;
        // margin-bottom: 170px;
        margin-bottom: 0px;
        display: flex;
        height: auto;
        // background-color: red;
        column-gap: 20px;

        .frameimgarticle1 {
          margin-top: 205px;
          margin-bottom: 25px;

          // 637x359
          width: 50%;

          img {
            width: 100%;
            height: 100%;
            max-width: 660px;
            // max-height: 359px;
            // max-height: 900px;
            object-fit: cover;
            // content: url("https://contrau.metacoders.dev/wp-content/uploads/2022/07/imgarticle1.png");
          }
        }
        .frameimgarticle2 {
          margin-top: 205px;
          margin-bottom: 25px;
          // height: auto;
          // margin-left: auto;
          width: 50%;
          img {
            width: 100%;
            height: 100%;
            max-width: 660px;
            // max-height: 900px;
            object-fit: cover;
            // content: url("https://contrau.metacoders.dev/wp-content/uploads/2022/07/imgarticle2.png");
          }
        }

        .frameimgarticle3 {
          // margin: auto;
          margin-top: 125px;
          img {
            width: 100%;
            height: 100%;
            max-width: 1320px;
            // max-height: 900px;
            object-fit: cover;

            // content: url("https://contrau.metacoders.dev/wp-content/uploads/2022/07/imgarticle3.png");
          }
          p {
            width: 1320px;
            height: 22px;

            font-family: "Roboto Flex", sans-serif;
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 22px;
            /* identical to box height, or 157% */

            letter-spacing: -0.02em;

            color: #999999;
            text-align: left;
            /* Inside auto layout */
          }
        }
        .frameimgarticle4 {
          margin-top: 120px;
          img {
            width: 100%;
            height: 100%;
            max-width: 1320px;
            // max-height: 900px;
            object-fit: cover;
          }
          p {
            display: inline-block;
            margin-bottom: 110px;
            width: 1320px;
            height: 22px;

            font-family: "Roboto Flex", sans-serif;
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 22px;
            /* identical to box height, or 157% */

            letter-spacing: -0.02em;

            color: #999999;
            text-align: left;
            /* Inside auto layout */
          }
        }
      }
    }
  }
}

// RESPONSIVE

/* MOBILE WIDTH < 769px */
@media screen and (max-width: 769px) {
  .rootpagedetail {
    // height: 100%;
    height: 100%;
    .framecontentmain {
      display: flex;
      justify-content: left;
      padding-left: 60px;
      .detailcontent-active {
        width: 100px;

        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 160% */
        letter-spacing: -0.02em;
        color: #101010;

        border-bottom: 2px solid black;

        padding-bottom: 15px;
        cursor: pointer;
      }
      .detailcontent {
        /* Gallery */
        width: 100px;

        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 160% */
        padding-bottom: 15px;

        letter-spacing: -0.02em;
        color: #999999;
        cursor: pointer;
      }
    }
    .framearticle {
      .framehtmlinterview {
        width: auto;
        margin: auto;
        height: auto;
        .framequote {
          width: 100%;
          height: auto;
          margin: auto;
          margin-top: 40px;
          .framedot1 {
            width: 60px;
            height: 60px;
            margin-left: 12px;
            margin-top: 0px;
          }
          .frametitleqoute {
            margin-left: 0px;
            margin-right: 0px;
            margin: auto;
            font-size: 24px;
            line-height: 30px;
            letter-spacing: 0.02em;
            color: #000000;
            // width: 259px;
            width: 70%;
            // height: 48px;
            text-align: center;
            .title1 {
              font-weight: 100;
              --underline-intrinsic-width: 8;

              --underline-width: 12;

              --underline-color: rgba(219, 47, 51, 0.929);

              --underline-cap-width: 4px;

              --underline-offset-y: -5px;

              --underline-padding-x: 0em;
              font-size: 30px;
              overflow-wrap: break-word;
              span {
                font-style: italic;
                font-weight: 800;
                display: inline;
                --underline-width-scale: calc(
                  var(--underline-width) / var(--underline-intrinsic-width)
                );
                padding: 0
                  calc(
                    var(--underline-padding-x) +
                      calc(
                        var(--underline-cap-width) *
                          var(--underline-width-scale)
                      )
                  );
                box-decoration-break: clone;
                background-repeat: no-repeat;
                background-image: linear-gradient(
                  180deg,
                  var(--underline-color),
                  var(--underline-color)
                );
                background-position-x: calc(
                    var(--underline-cap-width) * var(--underline-width-scale)
                  ),
                  0, 100%;
                background-position-y: calc(
                  100% - var(--underline-offset-y) * -1
                );
                background-size: calc(
                      100% -
                        calc(
                          var(--underline-cap-width) *
                            var(--underline-width-scale) * 2
                        )
                    )
                    calc(var(--underline-width) * 1px),
                  auto calc(var(--underline-width) * 1px),
                  auto calc(var(--underline-width) * 1px);
              }
            }
          }
          .framedot2 {
            width: 60px;
            height: 60px;
            margin-left: auto;
            margin-right: 12px;
            margin-bottom: 0px;
            margin-top: 0px;
          }
        }

        .framearticle {
          width: 100%;
          margin: auto;
          padding-left: 35px;
          padding-right: 35px;
          margin-top: 50px;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 24px;
          text-align: left;
          /* or 180% */
          letter-spacing: -0.02em;
          /* BLACK */
          color: #101010;
          .detailarticle {
            p {
              display: inline-block;
              margin-top: 10px;
              margin-bottom: 10px;
            }
            div {
              margin-bottom: 50px;
            }
          }
        }
        .frameimg {
          margin-top: 20px;
          margin-left: 0px;
          margin-right: 0px;
          width: 100%;
          // height: 450px;
          margin: auto;
          margin-top: 90px;
          img {
            width: 100%;
            height: 100%;
            max-width: 769px;
            // max-height: 456px;
            object-fit: cover;
            // content: url("https://contrau.metacoders.dev/wp-content/uploads/2022/07/imagearticle.png");
          }
          p {
            font-size: 13px;
            text-align: center;
          }
        }
      }
      .framequestionmain {
        width: 100%;
        margin-top: 60px;

        .frametitle {
          width: 100%;
          height: auto;
          margin-top: 0px;
          margin-bottom: 60px;

          .detailtitle {
            width: 100%;
            height: 100%;
            padding-top: 35px;
            padding-bottom: 40px;
            margin: auto;
            padding-left: 35px;
            padding-right: 50px;
            font-family: "Poppins", sans-serif;
            font-style: normal;
            font-weight: 900;
            font-size: 24px;
            line-height: 30px;
            /* or 54px */
            text-align: left;
            letter-spacing: 0.02em;

            /* WHITE */
            overflow-wrap: break-word;
            color: #ffffff;
            .detailtitlered {
              // display: inline-block;
              // height: 26px;
              overflow-wrap: break-word;
              font-weight: 800;
              --underline-intrinsic-width: 8;

              --underline-width: 8;

              --underline-color: rgba(219, 47, 51, 0.929);

              --underline-cap-width: 0px;

              --underline-offset-y: -4px;

              --underline-padding-x: 0em;
              // width: fit-content;
              // display: inline-block;
              // height: 58px;
              // font-weight: 800;
              // border-bottom: 18px solid rgba(219, 47, 51, 0.9294117647);
              display: inline;
              --underline-width-scale: calc(
                var(--underline-width) / var(--underline-intrinsic-width)
              );
              padding: 0
                calc(
                  var(--underline-padding-x) +
                    calc(
                      var(--underline-cap-width) * var(--underline-width-scale)
                    )
                );
              box-decoration-break: clone;
              background-repeat: no-repeat;
              background-image: linear-gradient(
                180deg,
                var(--underline-color),
                var(--underline-color)
              );
              background-position-x: calc(
                  var(--underline-cap-width) * var(--underline-width-scale)
                ),
                0, 100%;
              background-position-y: calc(
                100% - var(--underline-offset-y) * -1
              );
              background-size: calc(
                    100% -
                      calc(
                        var(--underline-cap-width) *
                          var(--underline-width-scale) * 2
                      )
                  )
                  calc(var(--underline-width) * 1px),
                auto calc(var(--underline-width) * 1px),
                auto calc(var(--underline-width) * 1px);
            }
            span {
              font-weight: 100;
            }
          }
        }
        .framequestiontitle {
          width: 100%;
          margin: auto;
          margin-top: 60px;
          padding-left: 35px;
          padding-right: 35px;
          .questiontitle {
            width: 100%;
            text-align: left;

            font-size: 18px;
            line-height: 24px;
            /* identical to box height, or 140% */

            letter-spacing: -0.01em;
            /* BLACK */

            color: #101010;

            /* Inside auto layout */

            flex: none;
            order: 0;
            flex-grow: 0;
            .titlered {
              color: red;
            }
          }
          .articlequestion {
            margin-top: 25px;
            text-align: left;
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 24px;
            /* or 180% */

            /* BLACK */

            color: #101010;

            /* Inside auto layout */

            flex: none;
            order: 1;
            flex-grow: 0;
            .somecannot {
              display: inline-block;
              margin-top: 30px;
            }
            .thesecond {
              display: inline-block;
              margin-top: 35px;
            }

            .detailarticle2 {
              padding-top: 40px;
            }
          }
        }
        .framequestiontitle2 {
          width: 100%;
          margin: auto;
          margin-top: 60px;
          padding-left: 35px;
          padding-right: 35px;
          .questiontitle {
            width: 100%;
            text-align: left;

            font-size: 18px;
            line-height: 24px;
            /* identical to box height, or 140% */

            letter-spacing: -0.01em;
            /* BLACK */

            color: #101010;

            /* Inside auto layout */

            flex: none;
            order: 0;
            flex-grow: 0;
            .titlered {
              color: red;
            }
          }
          .articlequestion {
            margin-top: 25px;
            text-align: left;
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 24px;
            /* or 180% */

            /* BLACK */

            color: #101010;

            /* Inside auto layout */

            flex: none;
            order: 1;
            flex-grow: 0;
            .thesecond {
              display: inline-block;
              margin-top: 35px;
            }

            .detailarticle2 {
              padding-top: 40px;
            }
          }
        }

        .frameimgarticle {
          width: 100%;
          margin: auto;
          margin-top: 25px;
          display: flex;
          flex-direction: column;
          .frameimgarticle1 {
            width: 100%;
            margin-top: 30px;
            margin-bottom: 0px;
            img {
              width: 100%;
              height: 100%;
              max-width: 769px;
              // max-height: 659px;
              object-fit: cover;
              // content: url("https://contrau.metacoders.dev/wp-content/uploads/2022/07/imgarticle1mb.png");
            }
          }
          .frameimgarticle2 {
            width: 100%;
            margin-left: 0px;
            margin-top: 20px;
            margin-bottom: 0px;
            img {
              width: 100%;
              height: 100%;
              max-width: 769px;
              // max-height: 659px;
              object-fit: cover;
              // content: url("https://contrau.metacoders.dev/wp-content/uploads/2022/07/imgarticle2mb.png");
            }
          }
          .frameimgarticle3 {
            margin-top: 30px;
            img {
              width: 100%;
              height: 100%;
              max-width: 769px;
              // max-height: 456px;
              object-fit: cover;
              // URL WRONGNAME ==> img3
              // content: url("https://contrau.metacoders.dev/wp-content/uploads/2022/07/imgarticle4-mb.png");
            }
            p {
              width: auto;
              height: 22px;
              font-size: 13px;
              line-height: 22px;
              /* identical to box height, or 169% */
              text-align: center;
              letter-spacing: -0.02em;
            }
          }
          .frameimgarticle4 {
            margin-top: 30px;
            img {
              width: 100%;
              height: 100%;
              max-width: 769px;
              // max-height: 456px;
              object-fit: cover;
              // content: url("https://contrau.metacoders.dev/wp-content/uploads/2022/07/imgarticle3-mb.png");
            }
            p {
              display: inline-block;
              margin-bottom: 0px;
              width: 100%;
              height: 22px;
              font-size: 13px;
              line-height: 22px;
              /* identical to box height, or 169% */
              text-align: center;
              letter-spacing: -0.02em;
            }
          }
        }
      }
    }

    .framenew {
      width: 100%;
      margin: auto;
      margin-top: 120px;
      .framenewtitle {
        display: flex;
        height: 44px;
        width: 100%;
        margin: auto;
        padding-left: 30px;
        padding-right: 30px;
        text-align: center;
        /* H2 */
        font-size: 40px;
        line-height: 44px;
        /* identical to box height, or 100% */

        letter-spacing: -0.01em;
        color: #101010;

        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 0;
        .viewtitle {
          display: block;
          width: 68px;
          height: 24px;
          font-family: "Roboto Flex", sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 24px;
          /* identical to box height, or 171% */
          margin-left: auto;
          margin-top: 12px;
          /* BLACK */
          border-bottom: 1px solid #101010;
          color: #101010;

          /* Inside auto layout */
          flex: none;
          order: 0;
          flex-grow: 0;
        }
      }
      .framedetailnewmain {
        display: flex;
        flex-direction: column;
        margin-top: 50px;
        column-gap: 0px;
        padding-left: 35px;
        padding-right: 35px;
        margin-bottom: 70px;
        .framedetail {
          display: flex;
          margin: auto;
          flex-direction: column;
          width: 100%;
          height: 370px;
          text-align: left;
          margin-bottom: 30px;

          :nth-child(2) {
            color: red;
            margin-top: 10px;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            /* identical to box height, or 171% */

            /* Inside auto layout */

            flex: none;
            order: 0;
            flex-grow: 0;
          }
          :nth-child(3) {
            width: 100%;
            margin-top: 10px;
            font-size: 18px;
            /* or 142% */
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          :nth-child(3):hover {
            cursor: pointer;
            text-decoration: underline black 2px;
          }
          :nth-child(4) {
            width: 100%;
            margin-top: 10px;
            font-family: "Poppins", sans-serif;
            font-style: normal;
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }
}
/* TABLET WIDTH >=770px and width <  1279px*/
@media only screen and (min-width: 770px) and (max-width: 1279px) {
  .rootpagedetail {
    .framearticle {
      .framehtmlinterview {
        width: 100%;
        margin: auto;
        height: auto;
        z-index: 100;
        .framequote {
          width: 100%;
          height: auto; // RES ADD
          margin: auto;
          margin-top: 100px;
          .framedot1 {
            // RESPONSIVE
            margin-left: 61px;
            margin-top: 40px;
          }
          .frametitleqoute {
            margin-left: 0x;
            margin-right: 0px;
            margin: auto;
            width: 70%; //responsive
            height: auto; //responsive
            text-align: center; //responsive
            .title1 {
              font-weight: 100;
              --underline-intrinsic-width: 8;

              --underline-width: 18;

              --underline-color: rgba(219, 47, 51, 0.929);

              --underline-cap-width: 4px;

              --underline-offset-y: -7px;

              --underline-padding-x: 0em;
              overflow-wrap: break-word;
              span {
                font-style: italic;
                // width: fit-content;
                // display: inline-block;
                // height: 58px;
                // font-style: italic;
                font-weight: 800;
                // border-bottom: 18px solid rgba(219, 47, 51, 0.9294117647);
                display: inline;
                --underline-width-scale: calc(
                  var(--underline-width) / var(--underline-intrinsic-width)
                );
                padding: 0
                  calc(
                    var(--underline-padding-x) +
                      calc(
                        var(--underline-cap-width) *
                          var(--underline-width-scale)
                      )
                  );
                box-decoration-break: clone;
                background-repeat: no-repeat;
                background-image: linear-gradient(
                  180deg,
                  var(--underline-color),
                  var(--underline-color)
                );
                background-position-x: calc(
                    var(--underline-cap-width) * var(--underline-width-scale)
                  ),
                  0, 100%;
                background-position-y: calc(
                  100% - var(--underline-offset-y) * -1
                );
                background-size: calc(
                      100% -
                        calc(
                          var(--underline-cap-width) *
                            var(--underline-width-scale) * 2
                        )
                    )
                    calc(var(--underline-width) * 1px),
                  auto calc(var(--underline-width) * 1px),
                  auto calc(var(--underline-width) * 1px);
              }
            }
          }
          .framedot2 {
            width: fit-content;
            margin-left: auto;
            margin-right: 61px;
            margin-bottom: 0px;
            margin-top: 15px;
          }
        }

        .framearticle {
          width: 75%;
          margin: auto;
          margin-top: 40px;
          padding-left: 0px; // RES
          padding-right: 0px; // RES
        }
        .frameimg {
          width: 80%;
          // height: 450px;

          margin: auto;
          margin-top: 90px;

          img {
            width: 100%;
            height: 100%;
            max-width: 1008px;
            // max-height: 696px;
            object-fit: cover;
          }
          p {
            font-family: "Roboto Flex", sans-serif;
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 22px;
            /* identical to box height, or 157% */
            text-align: left;
            letter-spacing: -0.02em;

            color: #999999;
            /* Inside auto layout */

            flex: none;
            order: 1;
            align-self: stretch;
            flex-grow: 0;
          }
        }
      }
      .framequestionmain {
        width: 100%;
        margin-top: 50px;
        .frametitle {
          width: 100%;
          height: auto;
          background: #151515;
          margin-top: 150px;
          margin-bottom: 150px;
          .detailtitle {
            width: 80%;
            height: auto; //res add
            padding-top: 75px;
            padding-bottom: 58px;
          }
        }
        .framequestiontitle {
          width: 100%;
          margin: auto;
          margin-top: 180px;
          padding-left: 0px;
          padding-right: 0px;
          .questiontitle {
            margin: auto;
            width: 75%;
            text-align: left;
          }
          .articlequestion {
            margin: auto;
            margin-top: 40px;
            width: 75%;

            .somecannot {
              display: inline-block;
              margin-top: 30px;
            }

            .detailarticle2 {
              padding-top: 40px;
            }
          }
        }
        .framequestiontitle2 {
          width: 100%;
          margin: auto;
          margin-top: 110px;
          padding-left: 0px;
          padding-right: 0px;
          .questiontitle {
            margin: auto;
            width: 75%;
            text-align: left;
            .redcontent {
              color: #db2f33;
            }
          }
          .articlequestion {
            margin: auto;
            margin-top: 40px;
            width: 75%;
          }
        }

        .frameimgarticle {
          width: 80%;
          margin-top: 0px;
          // margin-top: 165px;
          // margin-bottom: 170px;
          margin-bottom: 0px;
          display: flex;
          // background-color: brown;

          column-gap: 20px;
          .frameimgarticle1 {
            width: 50%;
            // height: 300px;
            margin-top: 205px;
            margin-bottom: 25px;

            img {
              width: 100%;
              height: 100%;
              max-width: 494px;
              // max-height: 500px;
              object-fit: cover;
              // content: url("https://contrau.metacoders.dev/wp-content/uploads/2022/07/imgarticle1.png");
            }
          }
          .frameimgarticle2 {
            width: 50%;
            // height: 300px;
            margin-top: 205px;
            margin-bottom: 25px;
            img {
              width: 100%;
              height: 100%;
              max-width: 494px;
              // max-height: 500px;
              object-fit: cover;
              // content: url("https://contrau.metacoders.dev/wp-content/uploads/2022/07/imgarticle2.png");
            }
          }
          .frameimgarticle3 {
            margin-top: 125px;
            width: 100%;
            img {
              width: 100%;
              height: 100%;
              max-width: 1008px;
              // max-height: 696px;
              object-fit: cover;
            }
            p {
              width: 100%;
              height: 22px;

              font-family: "Roboto Flex", sans-serif;
              font-style: normal;
              font-weight: 300;
              font-size: 14px;
              line-height: 22px;
              /* identical to box height, or 157% */

              letter-spacing: -0.02em;

              color: #999999;
              text-align: left;
              /* Inside auto layout */
            }
          }
          .frameimgarticle4 {
            margin-top: 120px;
            width: 100%;
            img {
              width: 100%;
              height: 100%;
              max-width: 1008px;
              // max-height: 696px;
              object-fit: cover;
            }
            p {
              display: inline-block;
              margin-bottom: 110px;
              width: 100%;
              height: auto;
              font-family: "Roboto Flex", sans-serif;
              font-style: normal;
              font-weight: 300;
              font-size: 14px;
              line-height: 22px;
              /* identical to box height, or 157% */

              letter-spacing: -0.02em;

              color: #999999;
              text-align: left;
              /* Inside auto layout */
            }
          }
        }
      }
    }

    .framenew {
      width: 100%;
      margin: auto;
      margin-top: 150px;
      margin-bottom: 150px;
      .framenewtitle {
        width: auto;
        height: 60px;
        margin: auto;
        text-align: center;
        /* H2 */

        .viewtitle {
          display: none;
        }
      }
      .framedetailnewmain {
        display: flex;
        margin: auto;
        margin-top: 100px;
        column-gap: 20px;
        width: 80%;
        .framedetail {
          display: flex;

          flex-direction: column;
          width: 334px;
          height: 355px;
          text-align: left;
          border-bottom: 1px solid #101010;

          :nth-child(2) {
            color: red;
            margin-top: 15px;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            /* identical to box height, or 171% */

            color: #db2f33;
            /* Inside auto layout */

            flex: none;
            order: 0;
            flex-grow: 0;
          }
          :nth-child(3) {
            margin-top: 15px;

            font-size: 24px;
            line-height: 34px;
          }
          :nth-child(3):hover {
            cursor: pointer;
            text-decoration: underline black 2px;
          }
          :nth-child(4) {
            margin-top: 15px;

            font-size: 16px;
            line-height: 24px;
            /* or 150% */
            /* BLACK */
          }
        }
      }
    }
  }
}

/* PC WIDTH >=1280px and width < 1535px*/
@media only screen and (min-width: 1280px) and (max-width: 1535px) {
  .rootpagedetail {
    .framearticle {
      .framehtmlinterview {
        width: 100%;
        margin: auto;
        height: auto;
        z-index: 100;
        .framequote {
          width: 100%;
          height: auto; // RES ADD
          margin: auto;
          margin-top: 100px;
          .framedot1 {
            // RESPONSIVE
            margin-left: 61px;
            margin-top: 40px;
          }
          .frametitleqoute {
            margin-left: 0x;
            margin-right: 0px;
            margin: auto;
            width: 70%; //responsive
            height: auto; //responsive
            text-align: center; //responsive
            .title1 {
              font-weight: 100;
              --underline-intrinsic-width: 8;

              --underline-width: 18;

              --underline-color: rgba(219, 47, 51, 0.929);

              --underline-cap-width: 4px;

              --underline-offset-y: -7px;

              --underline-padding-x: 0em;
              overflow-wrap: break-word;
              span {
                font-style: italic;

                font-weight: 800;
                // border-bottom: 18px solid rgba(219, 47, 51, 0.9294117647);
                display: inline;
                --underline-width-scale: calc(
                  var(--underline-width) / var(--underline-intrinsic-width)
                );
                padding: 0
                  calc(
                    var(--underline-padding-x) +
                      calc(
                        var(--underline-cap-width) *
                          var(--underline-width-scale)
                      )
                  );
                box-decoration-break: clone;
                background-repeat: no-repeat;
                background-image: linear-gradient(
                  180deg,
                  var(--underline-color),
                  var(--underline-color)
                );
                background-position-x: calc(
                    var(--underline-cap-width) * var(--underline-width-scale)
                  ),
                  0, 100%;
                background-position-y: calc(
                  100% - var(--underline-offset-y) * -1
                );
                background-size: calc(
                      100% -
                        calc(
                          var(--underline-cap-width) *
                            var(--underline-width-scale) * 2
                        )
                    )
                    calc(var(--underline-width) * 1px),
                  auto calc(var(--underline-width) * 1px),
                  auto calc(var(--underline-width) * 1px);
              }
            }
          }
          .framedot2 {
            width: fit-content;
            margin-left: auto;
            margin-right: 61px;
            margin-bottom: 0px;
            margin-top: 15px;
          }
        }

        .framearticle {
          width: 75%;
          margin: auto;
          margin-top: 40px;
          padding-left: 0px; // RES
          padding-right: 0px; // RES
        }
        .frameimg {
          width: 80%;
          // height: 450px;
          margin: auto;
          margin-top: 90px;

          img {
            width: 100%;
            height: 100%;
            max-width: 1215px;
            // max-height: 833px;
            object-fit: cover;
          }
          p {
            font-family: "Roboto Flex", sans-serif;
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 22px;
            /* identical to box height, or 157% */
            text-align: left;
            letter-spacing: -0.02em;

            color: #999999;
            /* Inside auto layout */

            flex: none;
            order: 1;
            align-self: stretch;
            flex-grow: 0;
          }
        }
      }
      .framequestionmain {
        width: 100%;
        margin-top: 50px;
        .frametitle {
          width: 100%;
          height: auto;
          background: #151515;
          margin-top: 150px;
          margin-bottom: 150px;
          .detailtitle {
            width: 81%;
            height: auto; //res add
            padding-top: 75px;
            padding-bottom: 58px;
          }
        }
        .framequestiontitle {
          width: 100%;
          margin: auto;
          margin-top: 180px;
          padding-left: 0px;
          padding-right: 0px;
          .questiontitle {
            margin: auto;
            width: 75%;
            text-align: left;
          }
          .articlequestion {
            margin: auto;
            margin-top: 40px;
            width: 75%;

            .somecannot {
              display: inline-block;
              margin-top: 30px;
            }

            .detailarticle2 {
              padding-top: 40px;
            }
          }
        }
        .framequestiontitle2 {
          width: 100%;
          margin: auto;
          margin-top: 110px;
          padding-left: 0%;
          padding-right: 0%;
          .questiontitle {
            margin: auto;
            width: 75%;
            text-align: left;
            .redcontent {
              color: #db2f33;
            }
          }
          .articlequestion {
            margin: auto;
            margin-top: 40px;
            width: 75%;
          }
        }

        .frameimgarticle {
          width: 80%;
          margin-top: 0px;
          // margin-top: 165px;
          // margin-bottom: 170px;
          margin-bottom: 0px;
          display: flex;
          // background-color: brown;
          column-gap: 20px;
          .frameimgarticle1 {
            width: 50%;
            margin-top: 205px;
            margin-bottom: 25px;

            img {
              width: 100%;
              height: 100%;
              max-width: 576px;
              // max-height: 900px;
              object-fit: cover;
            }
          }
          .frameimgarticle2 {
            width: 50%;
            margin-top: 205px;
            margin-bottom: 25px;
            img {
              width: 100%;
              height: 100%;
              max-width: 576px;
              // max-height: 900px;
              object-fit: cover;
            }
          }
          .frameimgarticle3 {
            margin-top: 125px;
            width: 100%;
            img {
              width: 100%;
              height: 100%;
              max-width: 1215px;
              // max-height: 833px;
              object-fit: cover;
            }
            p {
              width: 100%;
              height: 22px;

              font-family: "Roboto Flex", sans-serif;
              font-style: normal;
              font-weight: 300;
              font-size: 14px;
              line-height: 22px;
              /* identical to box height, or 157% */

              letter-spacing: -0.02em;

              color: #999999;
              text-align: left;
              /* Inside auto layout */
            }
          }
          .frameimgarticle4 {
            margin-top: 120px;
            width: 100%;
            img {
              width: 100%;
              height: 100%;
              max-width: 1215px;
              // max-height: 833px;
              object-fit: cover;
            }
            p {
              display: inline-block;
              margin-bottom: 110px;
              width: 100%;
              height: 22px;

              font-family: "Roboto Flex", sans-serif;
              font-style: normal;
              font-weight: 300;
              font-size: 14px;
              line-height: 22px;
              /* identical to box height, or 157% */

              letter-spacing: -0.02em;

              color: #999999;
              text-align: left;
              /* Inside auto layout */
            }
          }
        }
      }
    }

    .framenew {
      width: 100%;
      margin: auto;
      margin-top: 150px;
      margin-bottom: 150px;
      .framenewtitle {
        width: auto;
        height: 60px;
        margin: auto;
        text-align: center;
        /* H2 */

        .viewtitle {
          display: none;
        }
      }
      .framedetailnewmain {
        display: flex;
        margin: auto;
        margin-top: 100px;
        column-gap: 20px;
        width: 80%;
        .framedetail {
          display: flex;

          flex-direction: column;
          width: 334px;
          height: 355px;
          text-align: left;
          border-bottom: 1px solid #101010;

          :nth-child(2) {
            color: red;
            margin-top: 15px;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            /* identical to box height, or 171% */

            color: #db2f33;
            /* Inside auto layout */

            flex: none;
            order: 0;
            flex-grow: 0;
          }
          :nth-child(3) {
            margin-top: 15px;

            font-size: 24px;
            line-height: 34px;
          }
          :nth-child(3):hover {
            cursor: pointer;
            text-decoration: underline black 2px;
          }
          :nth-child(4) {
            margin-top: 15px;

            font-size: 16px;
            line-height: 24px;
            /* or 150% */
            /* BLACK */
          }
        }
      }
    }
  }
}
